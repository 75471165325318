import React from "react"
import ErrorBoundary from "./src/components/ErrorBoundary"
/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it
import "./src/styles/global.css"
import "swiper/css"
import "swiper/css/autoplay"
import "swiper/css/effect-fade"

export const wrapRootElement = ({ element }) => {
  return <ErrorBoundary>{element}</ErrorBoundary>
}
