import React from "react"
import * as Sentry from "@sentry/gatsby"

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  componentDidCatch(error) {
    if (process.env.NODE_ENV === "production") {
      Sentry.captureException({ error })
    }
  }

  render() {
    return this.props.children
  }
}

export default ErrorBoundary
